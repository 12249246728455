<template>
	<div class="container-menu-cart">
		<div class="container-menu-cart-2">
			<div class="menu-cart">
				<MenuHeader :title="title"/>
				<ol type="1" start="1">
					<li v-for="(item, index) in $store.getters.cart" :key="index" class="items-cart">
						{{ index + 1 }}
						{{ item }}
						<button @click="delete_item(item)" style="border:none;"><img src="../assets/btn-delete.png" alt="" style="height: 2em;"></button>
					</li>
				</ol>
			</div>
			<MenuEmail/>
		</div>
	</div>
</template>

<script>
import MenuHeader from './MenuHeader.vue'
import MenuEmail from './MenuEmail.vue'
import TitleImg from '../assets/title_your_cart.png'

export default {
	name: 'MenuCart',
	components: { MenuHeader, MenuEmail },
	data() {
		return {
			title: TitleImg
		}
	},
    mounted() {
        console.log(this.cart());
    },
    methods: {
        cart: function() {
            return this.$store.getters.cart;
        },
		delete_item(item_deleted){
			this.$store.getters.cart.forEach(function(item, index, object) {
				if(item == item_deleted){
					object.splice(index, 1);
					console.log(item + ' BORRADO');
				}
			});
		}
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

/* CONTAINER PRINCIPAL */
.container-menu-cart {
	width: 100%;
	height: 100vh;
	background-color: white;
	margin: 0;
	padding: 0;

	display: flex;
	flex-direction: column;
}

/* MENU-PRINCIPAL */
.container-menu-cart-2 {
	display: flex;
}

.menu-cart {
	width: 80%;
	height: 100vh;
	background-color: #f7f6f6;
}

.items-cart {
	display: flex;
	flex-direction: row;

	justify-content: space-between;
	align-items: center;

	margin-left: 5em;
	margin-right: 15em;

}


</style>
