import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        user_cart: [],
        items_selected: [],
        // counter: null
    },
    getters: {
        cart: state => { return state.user_cart },
        items: state => { return state.items_selected }
        // data_counter: state => {
        //     return state.counter;
        // }
    },
    mutations: {
        ADD_CART(state) {
            console.log('ADD_CART_INICIADO');
            state.user_cart = state.items_selected;
            state.items_selected = [];
        },
        SELECT_ITEM(state, payload) {
            console.log('SELECT_ITEM_INICIADO');
            state.items_selected.push(payload);
            console.log(payload + ' Seleccionado');
        },
        REMOVE_ITEM(state, payload) {
			state.items_selected.forEach(function(item, index, object) {
				if(item == payload){
					object.splice(index, 1);
					console.log(item + ' Borrado');
				}
			});
            console.log(state.items_selected);
        }
        // increment(state) {
        //     state.counter = Number(state.counter) + 1;
        // },
        // decrement(state) {
        //     state.counter = Number(state.counter) - 1;
        // },
        // counter(state, payload) {
        //     state.counter = payload;
        // }
    },
    actions: {},
    modules: {}
})