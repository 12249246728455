<template>
	<div class="container-menu-header">
		<img :src="title" alt="Title" style="height: 2em;">
        <p>Select from the resources below.</p>
	</div>
</template>

<script>
export default {
	name: 'MenuHeader',
	props: {
		title: {
			type: Object,
			required: true
		}
	}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

/* CONTAINER PRINCIPAL */
.container-menu-header {
	width: 100%;
	height: 25vh;
	background-color: #f7f6f6;
	margin: 0;
	padding: 0;

	display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

</style>
