<template>
	<div class="container-menu-lateral">
		<div class="menu-lateral">
			<div class="menu-lateral-banner">
				<img src="../assets/logo.png" alt="Logo Keytruda" style="height: 6.7em;">
			</div>
			<div class="menu-lateral-button-hcp">
				<button @click="menu_hcp">
					<img src="../assets/btn_hcp_resources.png" alt="Hcp Resources for Keytruda" style="height: 5.5em;">
				</button>
			</div>
			<div class="menu-lateral-button-patient">
				<button @click="menu_patient">
					<img src="../assets/btn_patient_resources.png" alt="Patient Resources for Keytruda" style="height: 6.1em;">
				</button>
			</div>
			<div class="menu-lateral-button-access_support">
				<button @click="menu_access_support">
					<img src="../assets/btn_access_resources.png" alt="Access and Support Resources" style="height: 5.4em;">
				</button>
			</div>
			<div class="menu-lateral-button-cart">
				<button @click="menu_cart" style="border:none;"><img src="../assets/01-btn-view_cart.png" alt="VIEW CART" style="height:2.5em;"></button>
			</div>
			<div class="menu-lateral-button-logout">
				<button style="border:none;"><img src="../assets/03-btn-log-out.png" alt="Log Out" style="height:2.5em;"></button>
			</div>

		</div>
	</div>
</template>

<script>
export default {
	name: 'MenuLateral',
	methods: {
		menu_hcp() {
			this.$router.push({ name: "menu-hcp" });
		},
		menu_patient() {
			this.$router.push({ name: "menu-patient" });
		},
		menu_access_support() {
			this.$router.push({ name: "menu-access-support" });
		},
		menu_cart() {
			this.$router.push({ name: "menu-cart" });
		}
	}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

/* CONTAINER PRINCIPAL */
.container-menu-lateral {
	width: 20%;
	height: 100vh;
	background-color: blue;
	margin: 0;
	padding: 0;

	display: flex;
}

/* MENU-LATERAL */
.menu-lateral {
	width: 100%;
	height: 100vh;
	background-color: green;
}

.menu-lateral-banner {
	width: auto;
	height: 14%;

	display: flex;
	justify-content: center;
	align-items: center;
}

.menu-lateral-button-hcp {
	width: auto;
	height: 12%;

	display: flex;
	justify-content: center;
	align-items: center;
}

.menu-lateral-button-patient {
	width: auto;
	height: 12%;

	display: flex;
	justify-content: center;
	align-items: center;
}

.menu-lateral-button-access_support {
	width: auto;
	height: 12%;

	display: flex;
	justify-content: center;
	align-items: center;
}

.menu-lateral-button-cart {
	width: auto;
	height: 25%;

	display: flex;
	justify-content: center;
	align-items: center;
}
.menu-lateral-button-logout {
	width: auto;
	height: 25%;

	display: flex;
	justify-content: center;
	align-items: center;
}

</style>
