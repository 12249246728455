<template>
	<div class="container-menu-footer">
        <button @click="add_to_cart" style="border:none;"><img src="../assets/btn-add-to-cart-off.png" alt="Add to Cart" style="height:2.5em;"></button>
	</div>
</template>

<script>
export default {
	name: 'MenuFooter',
	props: {},
	mounted(){
		console.log(this.data_cart());
		console.log(this.data_items());
	},
	methods: {
		add_to_cart() {
			this.$store.commit("ADD_CART");
		},
		data_cart() {
			return this.$store.getters.cart;
		},
		data_items() {
			return this.$store.getters.items;
		}
	}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

/* CONTAINER PRINCIPAL */
.container-menu-footer {
	width: 100%;
	height: 25vh;
	background-color: #f7f6f6;
	margin: 0;
	padding: 0;

	display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

</style>
