import Vue from 'vue'
import Router from 'vue-router'

// Navegation

// Components
import MenuHcp from './components/MenuHcp.vue'
import MenuPatient from './components/MenuPatient.vue'
import MenuAccessSupport from './components/MenuAccessSupport.vue'
import MenuCart from './components/MenuCart.vue'

Vue.use(Router)

export default new Router({
    mode: 'hash',
    routes: [
        {
            name: 'menu-hcp',
            path: '/hcp',
            component: MenuHcp
        },
        {
            name: 'menu-patient',
            path: '/patient',
            component: MenuPatient
        },
        {
            name: 'menu-access-support',
            path: '/access-support',
            component: MenuAccessSupport
        },
        {
            name: 'menu-cart',
            path: '/cart',
            component: MenuCart
        },
        // {
        //     name: 'register-step-2',
        //     path: '/professional-information',
        //     props: true,
        //     component: RegisterStep2
        // }
    ]
})
