<template>
	<div class="container-menu-hcp">
		<MenuHeader :title="title"/>
		<div class="menu-hcp">
			<div class="row-hcp">
				<div class="btn-hcp">
					<button @click="select_item('1')" id="btn-1">Prescribing Information</button>
				</div>
				<div class="btn-hcp">
					<button @click="select_item('2')" id="btn-2">Guide for KEYTRUDA</button>
				</div>
			</div>
			<div class="row-hcp">
				<div class="btn-hcp">
					<button @click="select_item('3')" id="btn-3">Medication Guide</button>
				</div>
				<div class="btn-hcp">
					<button @click="select_item('4')" id="btn-4">Dosing/Testing Resource</button>
				</div>
			</div>
			<div class="row-hcp">
				<div class="btn-hcp">
					<button @click="select_item('5')" id="btn-5">KEYTRUDA Brochure</button>
				</div>
			</div>
		</div>
		<MenuFooter/>
	</div>
</template>

<script>
// Importamos JQuery
const $ = require('jquery')
// Lo declaramos globalmente
window.$ = $
import MenuHeader from './MenuHeader.vue'
import MenuFooter from './MenuFooter.vue'
import TitleImg from '../assets/title_hcp_resources_for_keytruda.png'

export default {
	name: 'MenuHcp',
	components: { MenuHeader, MenuFooter },
	data() {
		return {
			title: TitleImg
		}
	},
	mounted(){
		this.$store.getters.cart.forEach(item => {
			switch (item) {
				case 'Prescribing Information':
						$('#btn-1').addClass('btn-selected-on');
					break;
			
				case 'Guide for KEYTRUDA':
						$('#btn-2').addClass('btn-selected-on');
					break;
			
				case 'Medication Guide':
						$('#btn-3').addClass('btn-selected-on');
					break;
			
				case 'Dosing/Testing Resource':
						$('#btn-4').addClass('btn-selected-on');
					break;
			
				case 'KEYTRUDA Brochure':
						$('#btn-5').addClass('btn-selected-on');
					break;
			}
		});
		this.$store.getters.items.forEach(item => {
			switch (item) {
				case 'Prescribing Information':
						$('#btn-1').addClass('btn-selected-on');
					break;
			
				case 'Guide for KEYTRUDA':
						$('#btn-2').addClass('btn-selected-on');
					break;
			
				case 'Medication Guide':
						$('#btn-3').addClass('btn-selected-on');
					break;
			
				case 'Dosing/Testing Resource':
						$('#btn-4').addClass('btn-selected-on');
					break;
			
				case 'KEYTRUDA Brochure':
						$('#btn-5').addClass('btn-selected-on');
					break;
			}
		});
	},
	methods: {
		select_item(num_select) {
			switch (num_select) {
				case '1':
					if (this.$store.getters.items.includes('Prescribing Information')) {
						this.$store.commit("REMOVE_ITEM", "Prescribing Information")
						$('#btn-1').removeClass('btn-selected-on');
						$('#btn-1').addClass('btn-selected-off');
					} else {
						this.$store.commit("SELECT_ITEM", "Prescribing Information")
						$('#btn-1').removeClass('btn-selected-off');
						$('#btn-1').addClass('btn-selected-on');
					}
				break;
				case '2':
					if (this.$store.getters.items.includes('Guide for KEYTRUDA')) {
						this.$store.commit("REMOVE_ITEM", "Guide for KEYTRUDA")
						$('#btn-2').removeClass('btn-selected-on');
						$('#btn-2').addClass('btn-selected-off');
					} else {
						this.$store.commit("SELECT_ITEM", "Guide for KEYTRUDA")
						$('#btn-2').removeClass('btn-selected-off');
						$('#btn-2').addClass('btn-selected-on');
					}
				break;
				case '3':
					if (this.$store.getters.items.includes('Medication Guide')) {
						this.$store.commit("REMOVE_ITEM", "Medication Guide")
						$('#btn-3').removeClass('btn-selected-on');
						$('#btn-3').addClass('btn-selected-off');
					} else {
					this.$store.commit("SELECT_ITEM", "Medication Guide")
						$('#btn-3').removeClass('btn-selected-off');
						$('#btn-3').addClass('btn-selected-on');
					}
				break;
				case '4':
					if (this.$store.getters.items.includes('Dosing/Testing Resource')) {
						this.$store.commit("REMOVE_ITEM", "Dosing/Testing Resource")
						$('#btn-4').removeClass('btn-selected-on');
						$('#btn-4').addClass('btn-selected-off');
					} else {
					this.$store.commit("SELECT_ITEM", "Dosing/Testing Resource")
						$('#btn-4').removeClass('btn-selected-off');
						$('#btn-4').addClass('btn-selected-on');
					}
				break;
				case '5':
					if (this.$store.getters.items.includes('KEYTRUDA Brochure')) {
						this.$store.commit("REMOVE_ITEM", "KEYTRUDA Brochure")
						$('#btn-5').removeClass('btn-selected-on');
						$('#btn-5').addClass('btn-selected-off');
					} else {
					this.$store.commit("SELECT_ITEM", "KEYTRUDA Brochure")
						$('#btn-5').removeClass('btn-selected-off');
						$('#btn-5').addClass('btn-selected-on');
					}
				break;
			}
		}
	}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

/* CONTAINER PRINCIPAL */
.container-menu-hcp {
	width: 100%;
	height: 100vh;
	background-color: #f7f6f6;
	margin: 0;
	padding: 0;

	display: flex;
	flex-direction: column;
}

/* MENU-PRINCIPAL */
.menu-hcp {
	width: 100%;
	height: 75vh;
	/* background-color: yellowgreen; */

	display: flex;
	flex-direction: column;
}

.row-hcp {
	width: 100%;
	height: 5em;
	/* background-color: brown; */

	display: flex;
}

.btn-hcp {
	width: 50%;
	height: auto;

	display: flex;
	justify-content: center;
	align-items: center;
}

.btn-selected-off {
	background-color: none;
}

.btn-selected-on {
	background-color: green;
}

</style>
