<template>
	<div class="container-menu-access-support">
		<MenuHeader :title="title"/>
		<div class="menu-access-support">
			<div class="row-access-support">
				<div class="btn-access-support">
					<button @click="select_item('8')" id="btn-8">Enrollment Form</button>
				</div>
				<div class="btn-access-support">
					<button @click="select_item('9')" id="btn-9">Billing Guide</button>
				</div>
			</div>
			<div class="row-access-support">
				<div class="btn-access-support">
					<button @click="select_item('10')" id="btn-10">Spanish Enrollment Form</button>
				</div>
				<div class="btn-access-support">
					<button @click="select_item('11')" id="btn-11">Sample CMS-1500 Form</button>
				</div>
			</div>
			<div class="row-access-support">
				<div class="btn-access-support">
					<button @click="select_item('12')" id="btn-12">Representative’s Form</button>
				</div>
				<div class="btn-access-support">
					<button @click="select_item('13')" id="btn-13">Sample UB-04 Form</button>
				</div>
			</div>
			<div class="row-access-support">
				<div class="btn-access-support">
					<button @click="select_item('14')" id="btn-14">Sample Enrollment Form</button>
				</div>
				<div class="btn-access-support">
					<button @click="select_item('15')" id="btn-15">Prior Authorization Checklist</button>
				</div>
			</div>
			<div class="row-access-support">
				<div class="btn-access-support">
					<button @click="select_item('16')" id="btn-16">Guide for KEYTRUDA</button>
				</div>
				<div class="btn-access-support">
					<button @click="select_item('17')" id="btn-17">Appeal Checklist</button>
				</div>
			</div>
		</div>
		<MenuFooter/>
	</div>
</template>

<script>
// Importamos JQuery
const $ = require('jquery')
// Lo declaramos globalmente
window.$ = $

import MenuHeader from './MenuHeader.vue'
import MenuFooter from './MenuFooter.vue'
import TitleImg from '../assets/title_access_and_support.png'

export default {
	name: 'MenuAccessSupport',
	components: { MenuHeader, MenuFooter },
	data() {
		return {
			title: TitleImg
		}
	},
	mounted() {
		this.$store.getters.cart.forEach(item => {
			switch (item) {
				case 'Enrollment Form':
					$('#btn-8').addClass('btn-selected-on');
					break;
			
				case 'Billing Guide':
					$('#btn-9').addClass('btn-selected-on');
					break;

				case 'Spanish Enrollment Form':
					$('#btn-10').addClass('btn-selected-on');
					break;

				case 'Sample CMS-1500 Form':
					$('#btn-11').addClass('btn-selected-on');
					break;

				case 'Representative’s Form':
					$('#btn-12').addClass('btn-selected-on');
					break;

				case 'Sample UB-04 Form':
					$('#btn-13').addClass('btn-selected-on');
					break;

				case 'Sample Enrollment Form':
					$('#btn-14').addClass('btn-selected-on');
					break;

				case 'Prior Authorization Checklist':
					$('#btn-15').addClass('btn-selected-on');
					break;

				case 'Guide for KEYTRUDA':
					$('#btn-16').addClass('btn-selected-on');
					break;

				case 'Appeal Checklist':
					$('#btn-17').addClass('btn-selected-on');
					break;
			}
		});
		this.$store.getters.items.forEach(item => {
			switch (item) {
				case 'Enrollment Form':
					$('#btn-8').addClass('btn-selected-on');
					break;
			
				case 'Billing Guide':
					$('#btn-9').addClass('btn-selected-on');
					break;

				case 'Spanish Enrollment Form':
					$('#btn-10').addClass('btn-selected-on');
					break;

				case 'Sample CMS-1500 Form':
					$('#btn-11').addClass('btn-selected-on');
					break;

				case 'Representative’s Form':
					$('#btn-12').addClass('btn-selected-on');
					break;

				case 'Sample UB-04 Form':
					$('#btn-13').addClass('btn-selected-on');
					break;

				case 'Sample Enrollment Form':
					$('#btn-14').addClass('btn-selected-on');
					break;

				case 'Prior Authorization Checklist':
					$('#btn-15').addClass('btn-selected-on');
					break;

				case 'Guide for KEYTRUDA':
					$('#btn-16').addClass('btn-selected-on');
					break;

				case 'Appeal Checklist':
					$('#btn-17').addClass('btn-selected-on');
					break;
			}
		});
	},
	methods: {
		select_item(num_select) {
			switch (num_select) {
				case '8':
					if (this.$store.getters.items.includes('Enrollment Form')) {
						this.$store.commit("REMOVE_ITEM", "Enrollment Form")
						$('#btn-8').removeClass('btn-selected-on');
						$('#btn-8').addClass('btn-selected-off');
					} else {
						this.$store.commit("SELECT_ITEM", "Enrollment Form")
						$('#btn-8').removeClass('btn-selected-off');
						$('#btn-8').addClass('btn-selected-on');
					}
				break;
				case '9':
					if (this.$store.getters.items.includes('Billing Guide')) {
						this.$store.commit("REMOVE_ITEM", "Billing Guide")
						$('#btn-9').removeClass('btn-selected-on');
						$('#btn-9').addClass('btn-selected-off');
					} else {
						this.$store.commit("SELECT_ITEM", "Billing Guide")
						$('#btn-9').removeClass('btn-selected-off');
						$('#btn-9').addClass('btn-selected-on');
					}
				break;
				case '10':
					if (this.$store.getters.items.includes('Spanish Enrollment Form')) {
						this.$store.commit("REMOVE_ITEM", "Spanish Enrollment Form")
						$('#btn-10').removeClass('btn-selected-on');
						$('#btn-10').addClass('btn-selected-off');
					} else {
						this.$store.commit("SELECT_ITEM", "Spanish Enrollment Form")
						$('#btn-10').removeClass('btn-selected-off');
						$('#btn-10').addClass('btn-selected-on');
					}
				break;
				case '11':
					if (this.$store.getters.items.includes('Sample CMS-1500 Form')) {
						this.$store.commit("REMOVE_ITEM", "Sample CMS-1500 Form")
						$('#btn-11').removeClass('btn-selected-on');
						$('#btn-11').addClass('btn-selected-off');
					} else {
						this.$store.commit("SELECT_ITEM", "Sample CMS-1500 Form")
						$('#btn-11').removeClass('btn-selected-off');
						$('#btn-11').addClass('btn-selected-on');
					}
				break;
				case '12':
					if (this.$store.getters.items.includes('Representative’s Form')) {
						this.$store.commit("REMOVE_ITEM", "Representative’s Form")
						$('#btn-12').removeClass('btn-selected-on');
						$('#btn-12').addClass('btn-selected-off');
					} else {
						this.$store.commit("SELECT_ITEM", "Representative’s Form")
						$('#btn-12').removeClass('btn-selected-off');
						$('#btn-12').addClass('btn-selected-on');
					}
				break;
				case '13':
					if (this.$store.getters.items.includes('Sample UB-04 Form')) {
						this.$store.commit("REMOVE_ITEM", "Sample UB-04 Form")
						$('#btn-13').removeClass('btn-selected-on');
						$('#btn-13').addClass('btn-selected-off');
					} else {
						this.$store.commit("SELECT_ITEM", "Sample UB-04 Form")
						$('#btn-13').removeClass('btn-selected-off');
						$('#btn-13').addClass('btn-selected-on');
					}
				break;
				case '14':
					if (this.$store.getters.items.includes('Sample Enrollment Form')) {
						this.$store.commit("REMOVE_ITEM", "Sample Enrollment Form")
						$('#btn-14').removeClass('btn-selected-on');
						$('#btn-14').addClass('btn-selected-off');
					} else {
						this.$store.commit("SELECT_ITEM", "Sample Enrollment Form")
						$('#btn-14').removeClass('btn-selected-off');
						$('#btn-14').addClass('btn-selected-on');
					}
				break;
				case '15':
					if (this.$store.getters.items.includes('Prior Authorization Checklist')) {
						this.$store.commit("REMOVE_ITEM", "Prior Authorization Checklist")
						$('#btn-15').removeClass('btn-selected-on');
						$('#btn-15').addClass('btn-selected-off');
					} else {
						this.$store.commit("SELECT_ITEM", "Prior Authorization Checklist")
						$('#btn-15').removeClass('btn-selected-off');
						$('#btn-15').addClass('btn-selected-on');
					}
				break;
				case '16':
					if (this.$store.getters.items.includes('Guide for KEYTRUDA')) {
						this.$store.commit("REMOVE_ITEM", "Guide for KEYTRUDA")
						$('#btn-16').removeClass('btn-selected-on');
						$('#btn-16').addClass('btn-selected-off');
					} else {
						this.$store.commit("SELECT_ITEM", "Guide for KEYTRUDA")
						$('#btn-16').removeClass('btn-selected-off');
						$('#btn-16').addClass('btn-selected-on');
					}
				break;
				case '17':
					if (this.$store.getters.items.includes('Appeal Checklist')) {
						this.$store.commit("REMOVE_ITEM", "Appeal Checklist")
						$('#btn-17').removeClass('btn-selected-on');
						$('#btn-17').addClass('btn-selected-off');
					} else {
						this.$store.commit("SELECT_ITEM", "Appeal Checklist")
						$('#btn-17').removeClass('btn-selected-off');
						$('#btn-17').addClass('btn-selected-on');
					}
				break;
			}
		}
	}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

/* CONTAINER PRINCIPAL */
.container-menu-access-support {
	width: 100%;
	height: 100vh;
	background-color: #f7f6f6;
	margin: 0;
	padding: 0;

	display: flex;
	flex-direction: column;
}

/* MENU-PRINCIPAL */
.menu-access-support {
	width: 100%;
	height: 75vh;
	/* background-color: yellowgreen; */

	display: flex;
	flex-direction: column;
}

.row-access-support {
	width: 100%;
	height: 5em;
	/* background-color: brown; */

	display: flex;
}

.btn-access-support {
	width: 50%;
	height: auto;

	display: flex;
	justify-content: center;
	align-items: center;
}

.btn-selected-off {
	background-color: none;
}

.btn-selected-on {
	background-color: green;
}

</style>
