<template>
	<div class="container-menu-patient">
		<MenuHeader :title="title"/>
		<div class="menu-patient">
			<div class="row-patient">
				<div class="btn-patient">
					<button @click="select_item('6')" id="btn-6">Patient Checklist</button>
				</div>
				<div class="btn-patient">
					<button @click="select_item('7')" id="btn-7">Doctor Discussion Guide</button>
				</div>
			</div>
		</div>
		<MenuFooter/>
	</div>
</template>

<script>
// Importamos JQuery
const $ = require('jquery')
// Lo declaramos globalmente
window.$ = $

import MenuHeader from './MenuHeader.vue'
import MenuFooter from './MenuFooter.vue'
import TitleImg from '../assets/title_patient_resources_for_keytruda.png'

export default {
	name: 'MenuPatient',
	components: { MenuHeader, MenuFooter },
	data() {
		return {
			title: TitleImg
		}
	},
	mounted() {
		this.$store.getters.cart.forEach(item => {
			switch (item) {
				case 'Patient Checklist':
					$('#btn-6').addClass('btn-selected-on');
				break;
			
				case 'Doctor Discussion Guide"':
					$('#btn-7').addClass('btn-selected-on');
				break;
			}
		});
		this.$store.getters.items.forEach(item => {
			switch (item) {
				case 'Patient Checklist':
					$('#btn-6').addClass('btn-selected-on');
				break;
			
				case 'Doctor Discussion Guide"':
					$('#btn-7').addClass('btn-selected-on');
				break;
			}
		});
	},
	methods: {
		select_item(num_select) {
			switch (num_select) {
				case '6':
					if (this.$store.getters.items.includes('Patient Checklist')) {
						this.$store.commit("REMOVE_ITEM", "Patient Checklist")
						$('#btn-6').removeClass('btn-selected-on');
						$('#btn-6').addClass('btn-selected-off');
					} else {
						this.$store.commit("SELECT_ITEM", "Patient Checklist")
						$('#btn-6').removeClass('btn-selected-off');
						$('#btn-6').addClass('btn-selected-on');
					}
				break;
				case '7':
					if (this.$store.getters.items.includes('Doctor Discussion Guide')) {
						this.$store.commit("REMOVE_ITEM", "Doctor Discussion Guide")
						$('#btn-7').removeClass('btn-selected-on');
						$('#btn-7').addClass('btn-selected-off');
					} else {
						this.$store.commit("SELECT_ITEM", "Doctor Discussion Guide")
						$('#btn-7').removeClass('btn-selected-off');
						$('#btn-7').addClass('btn-selected-on');
					}
				break;
			}
		}
	}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

/* CONTAINER PRINCIPAL */
.container-menu-patient {
	width: 100%;
	height: 100vh;
	background-color: #f7f6f6;
	margin: 0;
	padding: 0;

	display: flex;
	flex-direction: column;
}

/* MENU-PRINCIPAL */
.menu-patient {
	width: 100%;
	height: 75vh;
	/* background-color: yellowgreen; */

	display: flex;
	flex-direction: column;
}

.row-patient {
	width: 100%;
	height: 5em;
	/* background-color: brown; */

	display: flex;
}

.btn-patient {
	width: 50%;
	height: auto;

	display: flex;
	justify-content: center;
	align-items: center;
}

.btn-selected-off {
	background-color: none;
}

.btn-selected-on {
	background-color: green;
}

</style>
