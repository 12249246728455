<template>
  <div id="app">
    <MenuLateral/>
    <router-view/>
  </div>
</template>

<script>
import MenuLateral from './components/MenuLateral.vue'

export default {
  name: 'App',
  components: {
    MenuLateral
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  margin: 0;
  padding: 0;

  display: flex;
}
</style>
