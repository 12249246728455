<template>
	<div class="container-menu-email">
		<div class="menu-email">
			<div class="menu-email-banner">
				RECEIVE THE INFORMATION YOU HAVE SELECTED
			</div>
			<div class="menu-email-text-1">
				<span>
                    Note: If you did not register, please enter your e-mail address below,
                    check the box, and tap on SEND to have the information sent to you. 
                    Your e-mail address will be used only to send you this one-time 
                    e-mail and will not be retained.
                </span>
			</div>
			<div class="menu-email-text-2">
				<span>
                    If you registered, please check your e-mail address below for accuracy, 
                    correct it if needed, then check the box and tap on SEND
                </span>
			</div>
			<div class="menu-email-text-3">
                <input type="checkbox" name="checkbox" id="checkbox">
                <span>
                    I agree to receive by e-mail the information that I selected and is 
                    currently in my cart
                </span>
			</div>
			<div class="menu-email-input">
                <input type="email" name="email" id="email">
			</div>
			<div class="menu-email-button-logout">
				<button>SEND</button>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'MenuEmail',
	methods: {
		menu_hcp() {
			this.$router.push({ name: "menu-hcp" });
		}
	}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

/* CONTAINER PRINCIPAL */
.container-menu-email {
	width: 20%;
	height: 100vh;
	background-color: blue;
	margin: 0;
	padding: 0;

	display: flex;
}

/* MENU-LATERAL */
.menu-email {
	width: 100%;
	height: 100vh;
	background-color: white;
}

.menu-email-banner {
	width: auto;
	height: 15%;

	display: flex;
	justify-content: center;
	align-items: flex-end;

    text-align: start;
    padding-left: 1em;
}

.menu-email-text-1 {
	width: auto;
	height: 20%;

	display: flex;
	justify-content: flex-start; 
	align-items: center;

    font-size: 0.8em;
    text-align: start;
    padding-left: 1.5em;
}

.menu-email-text-2 {
	width: auto;
	height: 20%;

	display: flex;
	justify-content: center;
	align-items: flex-start;

    font-size: 0.8em;
    text-align: start;
    padding-left: 1.5em;
}

.menu-email-text-3 {
	width: auto;
	height: 15%;

	display: flex;
	justify-content: center;
	align-items: flex-start;

    font-size: 0.8em;
    text-align: start;
    padding-left: 1.5em;
}

.menu-email-text-3 #checkbox {
    size: 40px;
    /* margin-right: 1em; */
}

.menu-email-input {
    width: auto;
	height: 10%;

	display: flex;
    flex-direction: column;
	justify-content: center;
	align-items: center;
}

.menu-email-input #email {
    width: 90%;
	height: auto;

    margin-left: 1em;
    background: transparent;
    border-top: transparent;
    border-left: transparent;
    border-right: transparent;
}

.menu-email-input #email:focus {
    background: transparent;
    border-top: transparent;
    border-left: transparent;
    border-right: transparent;
}

.menu-email-button-send {
	width: auto;
	height: 15%;

	display: flex;
    flex-direction: column;
	justify-content: center;
	align-items: center;
}

/* TEXTS */
/* .email-text {
    font-size: 12px;
    text-align: start;
} */
</style>
